import './courses.scss';
import { Link } from 'react-scroll';

export default function Courses() {
  return (
    <section className="courses" name="courses" id="courses">
      <h1 className="heading">
        our <span>courses</span>
      </h1>
      <div className="box-container">
        <div className="box">
          <i className="fas fa-procedures"></i>
          <h3>Diploma in Healthcare Assistant (DHCA) </h3>
          <p>
            தாயினும் மேலான அக்கறையுடனும், பெற்ற மகளினும் மேலான பாசத்துடனும்,
            நோயாளிகளின் நம்பிக்கை தரும் சக்தியாக விளங்க, கல்வி அறிவுடன், தொழில்
            நுட்ப அறிவையும் வழங்கி ,நோயாளிகளைப் பராமரிக்கக் கற்றுத்
            தரப்படுகிறது.
          </p>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            Enquire Now <span className="fas fa-chevron-right"></span>
          </Link>
          {/* <a href="#enquire" target="_blank" className="btn">
            Enquire <span className="fas fa-chevron-right"></span>
          </a> */}
        </div>
        <div className="box">
          <i className="fa-solid fa-user-nurse"></i>
          <h3>Diploma in Hospital Nursing Midwifery (DHNM) </h3>
          <p>
            தாயினும் மேலான அக்கறையுடனும், பெற்ற மகளினும் மேலான பாசத்துடனும்,
            நோயாளிகளின் நம்பிக்கை தரும் சக்தியாக விளங்க, கல்வி அறிவுடன், தொழில்
            நுட்ப அறிவையும் வழங்கி நோயாளிகளைப் பராமரிக்கக் கற்றுத் தரப்படுகிறது.
          </p>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            Enquire Now <span className="fas fa-chevron-right"></span>
          </Link>
          {/* <a href="#enquire" target="_blank" className="btn">
            Enquire <span className="fas fa-chevron-right"></span>
          </a> */}
        </div>
        <div className="box">
          <i className="fa-solid fa-flask-vial"></i>
          <h3>Diploma in Medical Lab Technology </h3>
          <p>
            உடலில் நோயினை வர வைக்கும், கண்ணுக்கு தெரியாத கிருமிகளைக் கண்டறியும்
            இரத்தப் பரிசோதனை , சிறுநீர், மலம் பரிசோதனை, சளி பரிசோதனை ஆகியவற்றை
            திறம்படச் செய்யும் ஆய்வக நுட்புனர்களை உருவாக்கி, மருத்துவமனை மற்றும்
            ஆய்வகங்களில் பணிபுரியவும்,சொந்தமாக ஆய்வுக் கூடங்களை உருவாக்கவும்
            மாணவர்களுக்குக் கற்றுத் தரப்படுகின்றது.
          </p>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            Enquire Now <span className="fas fa-chevron-right"></span>
          </Link>
          {/* <a href="#enquire" target="_blank" className="btn">
            Enquire <span className="fas fa-chevron-right"></span>
          </a> */}
        </div>
        <div className="box">
          <i class="fa-solid fa-syringe"></i>
          <h3>Diploma in Operation theatre technology</h3>
          <p>
            ஆப்பரேஷன் செய்ய பயன்படுத்தும் கருவிகளைப் பராமரித்தல், அறுவை சிகிச்சை
            அரங்கத்தைக் கிருமித் தொற்று இல்லாமல் பராமரித்தல், மூலமாக அறுவை
            சிகிச்சையின் வெற்றிக்குப் பக்கபலமாக இருக்கும் வகையில் , தொழில்நுட்ப
            த்திறன் கொண்ட கல்வியினை வழங்கி மிளிர்கிறது..
          </p>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            Enquire Now <span className="fas fa-chevron-right"></span>
          </Link>
          {/* <a href="#enquire" target="_blank" className="btn">
            Enquire <span className="fas fa-chevron-right"></span>
          </a> */}
        </div>
        <div className="box">
          <i className="fa-solid fa-champagne-glasses"></i>
          <h3>Catering & Hotel Management</h3>
          <p>
            வயிராற உணவு தந்தோரை வாயார வாழ்த்தி மனதாரப் புகழும் வையகம்..
            விருந்தோம்பல் என்பது அனைவராலும் விரும்பத்தகுந்த ஒன்று.. உள்நாடு,
            வெளிநாட்டு வேலைவாய்ப்பு உடனடியாகக் கிடைக்கும் என்றால் உணவுத்
            தொழிலன்றி வேறெதுவும் இல்லை... உலகில் எத்தனை நாடுகளில் எத்தனை மொழிகள்
            மொழிகள் இருந்தாலும் ..."பசி என்ற மொழி...பார் அறிந்த ஒன்றே"... உணவே
            மருந்து என்பதும்...உடம்பை வளர்த்தேன் உயிர் வளர்த்தேன் என்பதும் ஊர்
            அறிந்த ஒன்றே!.... வேலை வாய்ப்பு தரும் ஹோட்டல் மேனேஜ்மென்ட் கல்வி...
            சிறந்த முறையில் கற்றுத் தரப்படுகிறது. உலகம் உள்ளவரை அழியாத இரு
            தொழில்கள் "உடல் வளர்க்கும் உணவுத் தொழிலும், உயிர் காக்கும்
            மருத்துவத் தொழிலுமே"'! ஆகும்... இந்த இரு தொழில்கள் சார்ந்த கல்வியினை
            மகாத்மா கல்லூரி வழங்கி வருகிறது... என்றென்றும்
            வேலைவாய்ப்பு!...18ஆண்டுகளாக!...
          </p>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            Enquire Now <span className="fas fa-chevron-right"></span>
          </Link>
          {/* <a href="#enquire" target="_blank" className="btn">
            Enquire <span className="fas fa-chevron-right"></span>
          </a> */}
        </div>
        <div className="box">
          <i className="fa-solid fa-plug-circle-bolt"></i>
          <h3>Electrical Electronics Technician & Electrical Technician </h3>
          <p>
            மின்சார பொருட்களும் மின்னணு உபகரணங்களும் மனித வாழ்வில் மூச்செனக்
            கலந்த ஒன்றாகும்.current is the part of current life என்பது மறுக்க
            முடியாத உண்மையே.. மனித வாழ்வின் ஒவ்வொரு தேவைக்குள்ளும்
            மறைந்திருக்கும் மகாசக்தி மின்சாரம் என்பது மாபெரும் சத்தியம்..
            அப்படிப்பட்ட நிலையில் இந்தத் துறை உலகத்தின் வெளிச்சம் என்று சொன்னால்
            அது மிகையாகாது.உலகைஆளும் இந்தக் கல்விக்கு வேலைவாய்ப்புகள் நிறைய
            உள்ளன என்பது நிதர்சனம்.
          </p>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            Enquire Now <span className="fas fa-chevron-right"></span>
          </Link>
          {/* <a href="#enquire" target="_blank" className="btn">
            Enquire <span className="fas fa-chevron-right"></span>
          </a> */}
        </div>
        <div className="box">
          <i className="fa-solid fa-masks-theater"></i>
          <h3>Beautician</h3>
          <p>
            அழகு படுத்தல் என்பது நேர்த்தியான ஒன்றாகும்.அழகாகத் தன்னை
            வெளிப்படுத்துதல், தன்னம்பிக்கையின் அடையாளம்.புத்துணர்தவைத் தந்து
            தன்னைப் புதுப்பித்தல் அழகுக் கலையின் அடிப்படை
            சாராம்சம்.தன்னம்பிக்கை, புத்துணர்ச்சி, புதுப்பித்தல்,நேர்த்தி என்பவை
            உள்ளத்திற்குத் தரும் உற்சாகம் ஆகும்."கந்தையானாலும் கசக்கிக்கட்டு"
            என்பது தூய்மையின் அடையாளம்.மனதிற்கு வலிமை தரும் அழகுக் கலை ஆண்டாண்டு
            காலமாக நம்முடன் கலந்த ஒன்றாகும்.அழகுக்கலை பயிற்சி... ஆளும் வலிமை
            தரும் பயிற்சி...
          </p>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            Enquire Now <span className="fas fa-chevron-right"></span>
          </Link>
          {/* <a href="#enquire" target="_blank" className="btn">
            Enquire <span className="fas fa-chevron-right"></span>
          </a> */}
        </div>
      </div>
    </section>
  );
}
