// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { Box } from '@mui/material';

import { Box, Grid } from '@mui/material';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useState } from 'react';

import './contactUS.scss';

export default function ContactUS() {
  const [course, setCourse] = useState('');

  const handleChange = (event) => {
    setCourse(event.target.value);
  };

  const [dateToContact, setDateToContact] = useState('');

  const handleDateChange = (date) => {
    setDateToContact(date);
  };
  return (
    <section className="contact_us" id="contact_us">
      <h1 className="heading">
        <span>Enquire </span> now
      </h1>
      <div className="row">
        <div className="image">
          <img
            src="/static/assets/images/enquire/college-admission.gif"
            alt=""
          />
        </div>
        {/* <Box px={3} py={2}> */}
        <form name="google-sheet-form" method="post">
          <Grid container spacing={1}>
            <TextField
              id="Name"
              name="Name"
              label="Enter Your Name"
              variant="outlined"
              className="box"
            />
          </Grid>
          <Grid container spacing={1}>
            <TextField
              id="mobileNumber"
              name="mobileNumber"
              label="Enter your Mobile Number"
              variant="outlined"
              className="box"
            />
          </Grid>
          <Grid container spacing={1}>
            <TextField
              id="email"
              name="email"
              label="Enter your E-mail"
              variant="outlined"
              className="box"
            />
          </Grid>
          {/* <Grid container spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="Pick date"
                  value={dateToContact}
                  className="box"
                  onChange={(date) => {
                    if (date) {
                      handleDateChange(date);
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid> */}
          <Grid container spacing={1}>
            <FormControl fullWidth>
              <InputLabel id="course-select-label">Course</InputLabel>
              <Select
                labelId="course-select-label"
                id="course-select"
                value={course}
                label="Course"
                onChange={handleChange}
                className="box_select"
              >
                <MenuItem value="DHCA">
                  Diploma in Healthcare Assistant
                </MenuItem>
                <MenuItem value="DHNM">
                  Diploma in Hospital Nursing Midwifery
                </MenuItem>
                <MenuItem value="DMLT">
                  Diploma in Medical Lab Technology
                </MenuItem>
                <MenuItem value="DOTT">
                  Diploma in Operation theatre technology
                </MenuItem>
                <MenuItem value="DCHT">Catering & Hotel Management</MenuItem>
                <MenuItem value="DEET">
                  Electrical Electronics Technician & Electrical Technician
                </MenuItem>
                <MenuItem value="Beautician">Beautician</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container spacing={1}>
            <input type="submit" value="Enquire now" className="btn" />
          </Grid>
        </form>
        {/* </Box> */}
      </div>
    </section>
  );
}
