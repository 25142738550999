import './copyrights.scss';

export default function Copyrights() {
  return (
    <footer>
      {/* <div className="copyrights">
        copyrights &copy; reserved by <span>Mahatma College</span>
      </div> */}
      <div className="copyrights">
        <div className="copyrights-wrapper">
          <p className="copyrights-text">
            <a className="copyrights-link" href="#" target="_self">
              ©2024. | Designed By: Mahathma College | All rights reserved.
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
