import '@splidejs/react-splide/css';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

import './aboutUS.scss';

export default function AboutUS() {
  return (
    <section className="about" id="about">
      <h1 className="heading">
        <span>about</span> us
      </h1>
      <div className="row">
        {/* <Splide
          hasTrack={false}
          options={{
            rewind: true,
            width: '50%',
            gap: '1rem',
            autoplay: true,
            interval: 2000,
            breakpoints: {
              767: {
                destroy: true,
                perPage: 1.5,
              },
              991: {
                destroy: true,
                perPage: 2.5,
              },
            },
          }}
          aria-label="..."
        >
          <SplideTrack>
            <SplideSlide>
              <img
                className="image"
                src="/static/assets/images/about-us/About-Us.jpg"
                alt="banner 0"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                className="image"
                src="/static/assets/images/about-us/Banner_0.jpg"
                alt="banner 0"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                className="image"
                src="/static/assets/images/about-us/Banner_1.jpg"
                alt="banner 1"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                className="image"
                src="/static/assets/images/about-us/Banner_2.jpg"
                alt="banner 2"
              />
            </SplideSlide>
          </SplideTrack>
        </Splide> */}
        <div className="image">
          <img src="/static/assets/images/about-us/About-Us.jpg" alt="" />
        </div>
        <div className="content">
          <h3> Mahatma Nursing and Catering College..</h3>
          <p>
            இந்தியத் திருநாட்டின் பொருளாதாரத்தை முன்னேற்றுவதற்காகவும்
            ,வேலையில்லாத் திண்டாட்டத்தைப் போக்குவதற்காகவும், கல்வி என்பது
            அனைத்து மக்களுக்கும் சமமான முறையில் சென்றடைய வேண்டும் என்ற
            நோக்கத்தில், மருத்துவம் சார்ந்த, மற்றும் தொழில் சார்ந்த படிப்புகளை
            மாணவர்களுக்கு வழங்கும் வகையில், 2006 ஆம் ஆண்டு புஷ்ப கணேசர்
            மெடிக்கல் ஃபவுண்டேசன் அண்ட் சேரிட்டபிள் டிரஸ்ட் மூலமாக மகாத்மா
            கல்லூரி துவங்கப்பட்டது.2006ம் ஆண்டு முதல் மகாத்மா நர்சிங் அண்டு
            கேட்டரிங் கல்லூரி என அறியப்பட்டு வருகிறது. கல்லூரியில் நர்ஸிங்,
            ஹெல்த்கேர் அஸிஸ்டெண்ட், லேப் டெக்னாலஜி, ஆப்ரேசன் தியேட்டர் டெக்னாலஜி
            உள்ளிட்ட துணை மருத்துவப் படிப்புகளும், கேட்டரிங் அண்டு ஹோட்டல்
            மேனேஜ்மென்ட், ஏசி மெக்கானிக், எலக்ட்ரிகல் & எலக்ட்ரானிக்ஸ்
            டெக்னீசியன்,எலக்ட்ரிசியன் போன்ற தொழில் சார்ந்த படிப்புகளும்,
            பியூட்டிசியன் போன்ற குறுகிய கால பயிற்சிகளும் வழங்கி வருகிறது.
            இதுவரையில் சுமார் 2000த்துக்கும் மேற்பட்ட மாணவ மாணவியர்கள் படித்து
            பயன்பெற்றுள்ளனர். தொடர்ந்து 17 ஆண்டுகளாக 100%. தேர்ச்சி மற்றும்வேலை
            வாய்ப்பினை மாணவர்களுக்கு வழங்கி வருகிறது.
          </p>
          <a href="#enquire" className="btn">
            Enquire <span className="fas fa-chevron-right"></span>
          </a>
        </div>
      </div>
    </section>
  );
}
