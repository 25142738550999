// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { Box } from '@mui/material';

import './staffs.scss';

export default function Staffs() {
  return (
    <section className="staffs" id="staffs">
      <h1 className="heading">
        Our <span>Founder</span>
      </h1>
      <div className="box-container">
        <div className="box">
          <img src="/static/assets/images/staffs/marirajan.jpg" alt="" />
          <h3>Dr. G. Marirajan</h3>
          <span>Chairman / Principal </span>
          <div className="share">
            <a href="#" className="fab fa-facebook-f"></a>
            <a href="#" className="fab fa-twitter"></a>
            <a href="#" className="fab fa-instagram"></a>
            <a href="#" className="fab fa-linkedin"></a>
          </div>
        </div>
      </div>
    </section>
  );
}
