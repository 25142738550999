import './features.scss';

export default function Features() {
  return (
    <section className="features" id="features">
      <div className="icons">
        <i className="fa-solid fa-calendar-days"></i>
        <h3>18+</h3>
        <p>Years of Teaching</p>
      </div>
      <div className="icons">
        <i className="fas fa-users"></i>
        <h3>2000+</h3>
        <p>Satisfied Students</p>
      </div>
      <div className="icons">
        <i className="fas fa-procedures"></i>
        <i className="fa-solid fa-bell-concierge"></i>
        <h3>20+</h3>
        <p>Hospital & Hotel Internship</p>
      </div>
      <div className="icons">
        <i className="fas fa-hospital"></i>
        <h3>100%</h3>
        <p>Placement</p>
      </div>
    </section>
  );
}
