import { Link } from 'react-scroll';

import './header.scss';

export default function Header(props) {
  return (
    <>
      {/* {props.showScrollTopButton && (
        <KeyboardDoubleArrowUpIcon
          className="top-btn-position top-btn-style"
          onClick={scrollTop}
        />
      )}
      <div className="header-shape"> 
        <img
          src="/static/assets/images/heropage/banner-mask.png"
          class="img-fluid w-100"
          alt="..."
        />
      </div>
      <header className="header">
        <a href="/" className="logo">
          <div className="logo-img-div">
            <span>
              <img
                src="/static/assets/images/logo/mahathma-logo-without-name.png"
                alt="MC"
              ></img>
            </span>
            <span>
              <h1 className="logo-txt-span"> Mahatma College </h1>
            </span>
          </div>
        </a>
        <nav className="navbar">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            Home
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            About us
          </Link>

          <Link
            activeClass="active"
            to="courses"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            Courses
          </Link>
          <Link
            activeClass="active"
            to="staffs"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            Staffs
          </Link>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            Contact
          </Link>
        </nav>
        <div id="menu-btn" className=" menu-btn fas fa-bars"></div>
      </header>*/}

      <div className="header-shape">
        <img
          src="/static/assets/images/heropage/banner-mask.png"
          class="img-fluid w-100"
          alt="..."
        />
      </div>
      <header className={props.showScrollTopButton ? 'header_blue' : 'header'}>
        <a href="/" className="logo">
          <div className="logo-img-div">
            <span>
              <img
                src="/static/assets/images/logo/mahathma-logo-without-name.png"
                alt="MC"
              ></img>
            </span>
            <span>
              <h1 className="logo-txt-span"> Mahatma College </h1>
            </span>
          </div>
        </a>
        <nav className="navbar">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
          >
            Home
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
          >
            About us
          </Link>

          <Link
            activeClass="active"
            to="courses"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
          >
            Courses
          </Link>
          <Link
            activeClass="active"
            to="staffs"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
          >
            Staffs
          </Link>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
          >
            Contact
          </Link>
        </nav>
        <div id="menu-btn" className=" menu-btn fas fa-bars"></div>
      </header>
    </>
  );
}
