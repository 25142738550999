import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useState, useEffect } from 'react';

import ScrollToTop from '../Components/scrollToTop/ScrollToTop';
import Header from '../Components/header/Header';
import StickyFooter from '../Components/staticFooter/StaticFooter';
import Copyrights from '../Components/copyrights/Copyrights';

import HeroPage from '../Pages/heropage/HeroPage';
import Features from '../Pages/features/Features';
import AboutUS from '../Pages/about/AboutUS';
import ContactUS from '../Pages/contact/ContactUS';
import Staffs from '../Pages/staffs/Staffs';
import Courses from '../Pages/courses/Courses';

const defaultTheme = createTheme();

export default function SinglePage() {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    });
  }, []);

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <ScrollToTop showScrollTopButton={showScrollTopButton} />
        <Header showScrollTopButton={showScrollTopButton} />
        <HeroPage />
        <Features />
        <Courses />
        <Staffs />
        <AboutUS />
        <ContactUS />
        <StickyFooter></StickyFooter>
        <Copyrights></Copyrights>
      </ThemeProvider>
    </>
  );
}
