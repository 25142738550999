import Header from './Components/header/Header';

import HeroPage from './Pages/heropage/HeroPage';
import Courses from './Pages/courses/Courses';
import Staffs from './Pages/staffs/Staffs';
import AboutUS from './Pages/about/AboutUS';
import ContactUS from './Pages/contact/ContactUS';

import SinglePage from './Layouts/SinglePage';

import StickyFooter from './Components/staticFooter/StaticFooter';
import Copyrights from './Components/copyrights/Copyrights';

export default function App() {
  return (
    <div className="App">
      {/* <Header />
      <HeroPage />
      <Courses />
      <Staffs />
      <AboutUS />
      <ContactUS /> */}
      <SinglePage></SinglePage>
      {/* <StickyFooter></StickyFooter>
      <Copyrights></Copyrights> */}
    </div>
  );
}
