import './heroPage.scss';

import { Link } from 'react-scroll';

export default function HeroPage() {
  return (
    <section className="home" id="home">
      <div className="content">
        <h3>Mahatma Nursing and Catering College</h3>
        {/* <p>
          Never stop Learning !!! uplift yourself with vocational skills and get
          certified with us !!!
        </p>
        <p>
          Mahatma College was started in 2006 by Pushpa Ganesar Medical
          Foundation and Charitable Trust to provide medical and vocational
          courses to the students in order to improve the economy of the country
          of India and to make education equally accessible to all the people.
          Known as College of Nursing and Catering
        </p> */}
        <p>
          தொட்டனைத் தூறும் மணற்கேணி மாந்தர்க்குக் கற்றனைத் தூறும் அறிவு. - குறள்
          396
        </p>
        <p>
          கலைஞர் விளக்கம்: தோண்டத் தோண்ட ஊற்றுநீர் கிடைப்பது போலத் தொடர்ந்து
          படிக்கப் படிக்க அறிவு பெருகிக் கொண்டே இருக்கும்.
        </p>
        <p>
          எங்களுடன் இணைந்து, தொழில் திறன்களால் உங்களை உயர்த்திக் கொள்ளுங்கள் !!!
        </p>
        {/* <a href="/contact" target="_blank" className="btn">
          contact us <span className="fas fa-chevron-right"></span>
        </a> */}
        <Link
          activeClass="active"
          to="contact_us"
          spy={true}
          smooth={true}
          // offset={50}
          duration={500}
          className="btn"
        >
          Contact us <span className="fas fa-chevron-right"></span>
        </Link>
      </div>
      <div className="banner_image_div">
        <img
          src="/static/assets/images/heropage/Thesis-pana.png"
          class="img-fluid w-100"
          alt="..."
        />
      </div>
    </section>
  );
}
