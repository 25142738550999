import './staticFooter.scss';
import { Link } from 'react-scroll';

export default function StickyFooter() {
  return (
    <section className="footer">
      <div className="box-container">
        <div className="box">
          <h3>Quick Links</h3>
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Home
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> About us
          </Link>
          <Link
            activeClass="active"
            to="courses"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Courses
          </Link>
          <Link
            activeClass="active"
            to="staffs"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Staffs
          </Link>
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Contact
          </Link>
          {/* <a href="/home">
            <i className="fas fa-chevron-right"></i> Home
          </a>
          <a href="/about">
            <i className="fas fa-chevron-right"></i> About us
          </a>
          <a href="/courses">
            <i className="fas fa-chevron-right"></i> Courses
          </a>
          <a href="staff">
            <i className="fas fa-chevron-right"></i> Staffs
          </a>
          <a href="/contact">
            <i className="fas fa-chevron-right"></i> Contact
          </a> */}
        </div>
        <div className="box">
          <h3>Our Courses</h3>

          <Link
            activeClass="active"
            to="courses"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Healthcare Assistant
          </Link>
          <Link
            activeClass="active"
            to="courses"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Medical Lab Technology
          </Link>
          <Link
            activeClass="active"
            to="courses"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Operation theatre
            technology
          </Link>
          <Link
            activeClass="active"
            to="courses"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Catering & Hotel Management
          </Link>
          <Link
            activeClass="active"
            to="courses"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
            className="btn"
          >
            <i className="fas fa-chevron-right"></i> Beautician
          </Link>

          {/* <a href="#courses">
            <i className="fas fa-chevron-right"></i> Healthcare Assistant
          </a>
          <a href="#courses">
            <i className="fas fa-chevron-right"></i> Medical Lab Technology
          </a>
          <a href="#courses">
            <i className="fas fa-chevron-right"></i> Operation theatre
            technology
          </a>
          <a href="#courses">
            <i className="fas fa-chevron-right"></i> Catering & Hotel Management
          </a>
          <a href="#courses">
            <i className="fas fa-chevron-right"></i> Beautician
          </a> */}
        </div>
        <div className="box">
          <h3>contact info</h3>
          <a href="#phone1">
            <i className="fas fa-phone"></i> +91 98420 68174
          </a>
          <a href="#phone2">
            <i className="fas fa-phone"></i> +91 96556 62626
          </a>
          <a href="#email">
            <i className="fas fa-envelope"></i> mahathma2006@gmail.com
          </a>
          <a href="#address">
            <i className="fas fa-map-marker-alt"></i> Madurai, Tamilnadu, India
            - 625001
          </a>
        </div>
        <div className="box">
          <h3>follow us</h3>
          <a href="#facebook">
            <i className="fab fa-facebook-f"></i> facebook
          </a>
          <a href="#twitter">
            <i className="fab fa-twitter"></i> twitter
          </a>
          <a href="#instagram">
            <i className="fab fa-instagram"></i> instagram
          </a>
          <a href="#linkedin">
            <i className="fab fa-linkedin"></i> linkedin
          </a>
        </div>
      </div>
    </section>
  );
}
